import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { push } from 'connected-react-router';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { connect } from 'react-redux';
import classNames from 'classnames';
import LoginAPI from '../../resources/login';
import utils from '../../utils';
import { getUserData, userLoggedIn } from '../../actions/Users';
import { setLoading } from '../../actions/Shared';
import Loader from '../Shared/Loader';
import styles from '../../styles';

const cookies = new Cookies();

class Login extends React.Component {
  state = {
    username: '',
    password: '',
    email: '',
  };

  initialize = () => {
    this.setState({
      error: null,
      errorField: null,
      success: null,
      submitClicked: false,
      username: '',
      password: '',
      email: '',
    });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      error: null,
    });
  };

  validateForm = () => {
    const { username, password } = this.state;

    return username && password;
  };

  handleErrors = (errorResponse) => {
    let state = { ...this.state };
    const { dispatch } = this.props;
    dispatch(setLoading(false));
    switch (errorResponse.status) {
      case 400:
        // noinspection JSUnresolvedVariable
        const errorField = errorResponse.data.errors[0].field;
        let error = errorResponse.data.errors[0].message;

        switch (errorField) {
          case 'username':
            error = 'Sorry that username is taken...try a different one';
            break;
          case 'password':
            error = 'Invalid password';
            break;
          default:
            error = utils.toSentenceCase(errorResponse.data.errors[0].message);
            break;
        }

        state = { ...state, errorField, error };
        break;
      case 403:
        state.error = errorResponse.data.message;
        break;
      case 500:
        state.error = `Something went wrong - but don${"'"}t worry, our team is checking it out`;
        break;
      default:
        state.error = `Something went wrong - but don${"'"}t worry, our team is checking it out`;
        break;
    }

    cookies.remove('accessToken', {
      path: '/',
      domain:
        process.env.NODE_ENV === 'production' ? 'gardentracker.app' : null,
    });

    this.setState(state);
  };

  /**
   * Logs in a user
   * @param aUser - a user to be logged in
   */
  loginUser = (aUser) => {
    const { dispatch } = this.props;
    dispatch(userLoggedIn(aUser));
    dispatch(getUserData(aUser.id));
    return dispatch(push('/gardens'));
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    sessionStorage.removeItem('userData');
    this.setState({
      submitClicked: true,
      error: null,
      errorField: null,
      success: null,
    });

    const { dispatch } = this.props;

    if (this.validateForm()) {
      let response;
      dispatch(setLoading(true));
      try {
        response = await LoginAPI.loginWithUser(this.state);
        dispatch(setLoading(false));
      } catch (err) {
        this.handleErrors(err.response);
        return;
      }

      const results = response.data;
      if (results.success) {
        cookies.set('accessToken', results.token, {
          secure: process.env.NODE_ENV === 'production',
          domain:
            process.env.NODE_ENV === 'production' ? 'gardentracker.app' : null,
          maxAge: 60 * 60 * 12,
          path: '/',
        });
        // eslint-disable-next-line consistent-return
        return this.loginUser(results.user);
      }
      cookies.remove('accessToken', {
        path: '/',
        domain:
          process.env.NODE_ENV === 'production' ? 'gardentracker.app' : null,
      });

      if (!response || !response.data) {
        this.setState({
          error: 'Sorry...an error occurred. Please try again.',
        });
      }

      this.setState({ isLoggedIn: true });
    }
    this.setState({ error: 'Please check your entry and try again' });
  };

  handleErrorAndSuccessMessage() {
    const { classes } = this.props;
    const { error, success } = this.state;

    if (error || success) {
      return (
        <Grid item xs={12}>
          <div className={error ? classes.error : classes.success}>
            {error || success}
          </div>
        </Grid>
      );
    }

    return null;
  }

  render() {
    const { classes, isFetching, loginAttempted } = this.props;
    const { username, password, errorField, submitClicked } = this.state;

    if (isFetching || !loginAttempted) {
      return (
        <Grid
          container
          align="center"
          direction="column"
          className={classNames(classes.grid, classes.padded)}
        >
          <Grid item xs={12}>
            <Loader />
          </Grid>
        </Grid>
      );
    }

    return (
      <div className={classes.root}>
        <form className={classes.container} noValidate autoComplete="off">
          <Grid
            className={classNames(classes.grid, classes.padded)}
            direction="column"
            align="center"
            container
            spacing={24}
          >
            <Grid item xs={12}>
              <h1 className={classes.title}>Welcome to Gardentracker</h1>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="username"
                className={classes.textField}
                value={username}
                name="username"
                onChange={this.handleChange}
                margin="normal"
                error={
                  submitClicked && (!username || errorField === 'username')
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="password"
                className={classes.textField}
                value={password}
                type="password"
                name="password"
                onChange={this.handleChange}
                error={
                  submitClicked && (!password || errorField === 'password')
                }
                margin="normal"
              />
            </Grid>
            {this.handleErrorAndSuccessMessage()}
            <Grid item xs={12}>
              <Button
                onClick={this.handleSubmit}
                variant="contained"
                className={classes.button}
                color="primary"
                type="submit"
              >
                Sign In
              </Button>
              or{' '}
              <Button
                component={Link}
                to="/signup"
                onClick={this.initialize}
                variant="outlined"
                color="primary"
                className={classes.button}
              >
                Sign Up
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                component={Link}
                onClick={this.initialize}
                to="/resetPassword"
                className={classes.button}
                color="secondary"
                variant="outlined"
              >
                Forgot Password?
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  loginAttempted: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { user: theUser, app } = state;
  const { user } = theUser;
  const { isFetching, loginAttempted } = app;

  return {
    loginAttempted,
    user,
    isFetching,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(Login));
