// eslint-disable-next-line import/no-cycle
import { logOutUser } from '../actions/Users';

export default {
  handleLoginResponse(err, dispatch) {
    if (
      err.response &&
      err.response.data &&
      err.response.data.message === 'Unable to validate token.'
    ) {
      // eslint-disable-next-line no-alert
      alert('Please sign in again');
      return dispatch(logOutUser());
    }
    return true;
  },
};
