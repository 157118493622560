import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/es/Grid/Grid';
import Typography from '@material-ui/core/es/Typography/Typography';
import PropTypes from 'prop-types/prop-types';
import styles from '../../styles';
import Loader from '../Shared/Loader';

class Profile extends React.Component {
  state = {};

  render() {
    const { user, classes } = this.props;

    if (!user) {
      return <Loader />;
    }

    return (
      <Grid
        className={classes.paddedTop36}
        container
        spacing={24}
        align="center"
        justify="center"
      >
        <Grid item xs={12}>
          <Typography variant="h4">Profile</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>Username:</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{user.username}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>Email:</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{user.email}</Typography>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  const { user: theUser, app, router } = state;
  const { isLoggedIn, user } = theUser;
  const { isFetching } = app;
  const { location } = router || { pathname: '' };
  const { pathname } = location;

  return {
    isLoggedIn,
    user,
    isFetching,
    pathname,
  };
};

Profile.defaultProps = {
  user: null,
};

Profile.propTypes = {
  user: PropTypes.object,
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(withStyles(styles)(Profile));
