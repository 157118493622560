import React from 'react';
import Grid from '@material-ui/core/Grid';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
// noinspection ES6CheckImport
import emailValidator from 'email-validator';
import styles from '../../../styles';
import UserAPI from '../../../resources/user';

class SendEmail extends React.Component {
  state = { email: '' };

  sendPasswordResetEmail = async () => {
    this.setState({ submitClicked: true, error: null });
    const { email } = this.state;

    if (!email || email.length === 0) {
      return this.setState({ error: 'Please complete all required fields' });
    }

    if (!emailValidator.validate(email)) {
      return this.setState({ error: 'Invalid email address' });
    }

    const response = await UserAPI.sendPasswordResetEmailForUser(email);

    if (response.successful) {
      this.setState({ message: 'Please check your email' });
    } else {
      this.setState({ error: response.message });
    }

    return null;
  };

  render() {
    const { classes } = this.props;
    const { email, error, submitClicked, message } = this.state;

    return (
      <Grid className={classes.grid} align="center" container spacing={24}>
        <Grid item xs={12}>
          <h1>Reset Your Password</h1>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="email"
            value={email}
            onChange={(e) => this.setState({ email: e.target.value })}
            error={submitClicked && !email}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={this.sendPasswordResetEmail}
            className={classes.button}
          >
            Reset Password
          </Button>
        </Grid>
        <Grid item xs={12}>
          <span className={classes.error}>{error}</span>
          <span className={classes.success}>{message}</span>
        </Grid>
      </Grid>
    );
  }
}

SendEmail.propTypes = {
  classes: propTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { user: theUser, app } = state;
  const { isLoggedIn, user } = theUser;
  const { isFetching } = app;

  return {
    isLoggedIn,
    user,
    isFetching,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(SendEmail));
