import axios from 'axios';
import Cookies from 'universal-cookie';
import config from './config';
// eslint-disable-next-line import/no-cycle
import utils from './utils';

const cookies = new Cookies();

const { apiURL } = config;

export default {
  addUser(aUser) {
    return axios.post(`${apiURL}/users`, aUser);
  },
  getDataForUser(id) {
    return axios.get(`${apiURL}/users/${id}/data`, {
      headers: {
        'x-access-token': cookies.get('accessToken'),
      },
    });
  },
  async getUsers(dispatch) {
    let response;
    try {
      response = await axios.get(`${apiURL}/users`, {
        headers: {
          'x-access-token': cookies.get('accessToken'),
        },
      });
    } catch (ex) {
      utils.handleLoginResponse(ex, dispatch);
      return { successful: false, message: ex.message };
    }

    response.successful = response.status === 200;

    return response;
  },
  async sendPasswordResetEmailForUser(email) {
    let response;
    try {
      response = await axios.post(
        `${apiURL}/users/resetPassword?email=${email}`,
      );
    } catch (ex) {
      if (ex.message.includes('404')) {
        return { successful: false, message: 'No user found with that email' };
      }

      return { successful: false, message: ex.message };
    }

    response.successful = response.status === 200;

    return response;
  },
  async updateUser(aUser, token) {
    let response;
    try {
      response = await axios.put(
        `${apiURL}/users/${aUser.id}?token=${token}`,
        aUser,
      );
    } catch (ex) {
      if (ex.message.includes('404')) {
        return { successful: false, message: 'No user found with that email' };
      }
      return { successful: false, message: ex.message };
    }

    response.successful = response.status === 200;

    return response;
  },

  async updateUserWithoutToken(aUser, dispatch) {
    let response;
    try {
      response = await axios.put(`${apiURL}/users/${aUser.id}`, aUser, {
        headers: {
          'x-access-token': cookies.get('accessToken'),
        },
      });
    } catch (ex) {
      utils.handleLoginResponse(ex, dispatch);
      return { successful: false, message: ex.message };
    }

    response.successful = response.status === 200;

    return response;
  },

  async deleteUser(aUser, dispatch) {
    let response;
    try {
      response = await axios.delete(`${apiURL}/users/${aUser.id}`, {
        headers: {
          'x-access-token': cookies.get('accessToken'),
        },
      });
    } catch (ex) {
      utils.handleLoginResponse(ex, dispatch);
      return { successful: false, message: ex.message };
    }

    response.successful = response.status === 200;

    return response;
  },
};
