import React from 'react';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import ReactRouterPropTypes from 'react-router-prop-types';
import PropTypes from 'prop-types/prop-types';
import Grid from '@material-ui/core/es/Grid/Grid';
import Typography from '@material-ui/core/es/Typography/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import moment from 'moment';
import classNames from 'classnames';
import globalStyles from '../../styles';
import Loader from '../Shared/Loader';
import Plant from './Plant';
import EditPlant from './EditPlant';

const styles = (theme) => ({
  ...globalStyles(theme),
});

class Plants extends React.Component {
  state = { addModalOpen: false };

  handleAddClick = () => {
    this.setState({ addModalOpen: true });
  };

  handleAddModalClose = () => {
    this.setState({ addModalOpen: false });
  };

  handleGardenClick = () => {
    const { dispatch } = this.props;
    return dispatch(push('/gardens'));
  };

  getEditDialog = (garden) => {
    const { addModalOpen } = this.state;
    return (
      <Dialog
        scroll="paper"
        open={addModalOpen}
        onClose={this.handleAddModalClose}
      >
        <EditPlant
          garden={garden}
          handleClose={this.handleAddModalClose}
          plant={{
            name: '',
            quantity: 1,
            location: '',
            date: moment.utc().format('YYYY-MM-DD'),
          }}
        />
      </Dialog>
    );
  };

  render() {
    const { userData, isLoggedIn, classes, match } = this.props;
    const { params } = match;

    if (!isLoggedIn || !userData || !userData.gardens) {
      return (
        <Grid container spacing={24} justify="center" align="center">
          <Grid item>
            <Loader />
          </Grid>
        </Grid>
      );
    }

    const garden = userData.gardens.find(
      (aGarden) => aGarden.id === parseInt(params.GardenId, 0),
    ) || { plants: [] };

    const { plants } = garden;

    if (plants.length === 0) {
      return (
        <div className={classes.root}>
          {this.getEditDialog(garden)}
          <Grid container spacing={24} justify="center" align="center">
            <Grid item xs={12}>
              <Typography className={classes.paddedTop20} variant="display1">
                No plants added
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={this.handleAddClick}
                className={classNames(classes.addButton, classes.noFloat)}
              >
                Add Plant
              </Button>
            </Grid>
          </Grid>
        </div>
      );
    }

    return (
      <div className={classes.root}>
        {this.getEditDialog(garden)}
        <Grid
          align="center"
          justify="space-evenly"
          container
          spacing={24}
          className={classes.grid}
        >
          <Grid item xs={6}>
            <Button
              className={classNames(classes.backButton)}
              variant="contained"
              onClick={this.handleGardenClick}
            >
              <Typography variant="subtitle2">{garden.name}</Typography>
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              className={classNames(classes.addButton)}
              variant="contained"
              onClick={this.handleAddClick}
            >
              <Typography className={classes.white} variant="subtitle2">
                Add Plant
              </Typography>
            </Button>
          </Grid>
          {plants.map((plant) => (
            <Grid item key={plant.id}>
              <Plant garden={garden} plant={Object.assign({}, plant)} />
            </Grid>
          ))}
        </Grid>
      </div>
    );
  }
}

Plants.defaultProps = {
  userData: null,
  isLoggedIn: null,
};

Plants.propTypes = {
  userData: PropTypes.object,
  isLoggedIn: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { user: theUser, app } = state;
  const { isLoggedIn, userData } = theUser;
  const { isFetching } = app;

  return {
    isLoggedIn,
    userData,
    isFetching,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(Plants));
