/* eslint-disable no-param-reassign */
const clone = (obj) => Object.assign({}, obj);

export default {
  addGardenToUserData(garden, userData) {
    const newUserData = clone(userData);
    sessionStorage.removeItem('userData');
    if (!garden.plants) garden.plants = [];
    newUserData.gardens.push(garden);
    return newUserData;
  },

  updateGardenInUserData(garden, userData) {
    const newUserData = clone(userData);
    sessionStorage.removeItem('userData');
    const index = newUserData.gardens.findIndex(
      (aGarden) => aGarden.id === garden.id,
    );
    garden.plants = newUserData.gardens[index].plants;
    newUserData.gardens[index] = garden;
    return newUserData;
  },

  deleteGardenFromUserData(garden, userData) {
    const newUserData = clone(userData);
    sessionStorage.removeItem('userData');
    newUserData.gardens = newUserData.gardens.filter(
      (aGarden) => aGarden.id !== garden.id,
    );
    return newUserData;
  },
  addPlantToGardenInUserData(plant, userData) {
    const newUserData = clone(userData);
    sessionStorage.removeItem('userData');
    if (!plant.entries) plant.entries = [];
    newUserData.gardens.every((garden) => {
      if (garden.id === plant.GardenId) {
        garden.plants.push(plant);
        return false;
      }
      return true;
    });
    return newUserData;
  },

  updatePlantInGardenInUserData(plant, userData) {
    const newUserData = clone(userData);
    sessionStorage.removeItem('userData');
    newUserData.gardens.every((garden) => {
      if (garden.id === plant.GardenId) {
        const index = garden.plants.findIndex(
          (aPlant) => aPlant.id === plant.id,
        );
        plant.entries = garden.plants[index].entries;
        garden.plants[index] = plant;
        return false;
      }
      return true;
    });
    return newUserData;
  },

  deletePlantFromGardenInUserData(plant, userData) {
    const newUserData = clone(userData);
    sessionStorage.removeItem('userData');
    newUserData.gardens.every((garden) => {
      if (garden.id === plant.GardenId) {
        garden.plants = garden.plants.filter(
          (aPlant) => aPlant.id !== plant.id,
        );
        return false;
      }
      return true;
    });
    return newUserData;
  },
  addEntryToPlantInGardenInUserData(entry, gardenId, userData) {
    const newUserData = clone(userData);
    sessionStorage.removeItem('userData');
    newUserData.gardens.every((garden) => {
      if (garden.id === gardenId) {
        garden.plants.every((plant) => {
          if (plant.id === entry.PlantId) {
            plant.entries.push(entry);
            return false;
          }
          return true;
        });
        return false;
      }
      return true;
    });
    return newUserData;
  },

  updateEntryInPlantInInGardenInUserData(entry, gardenId, userData) {
    const newUserData = clone(userData);
    sessionStorage.removeItem('userData');
    newUserData.gardens.every((garden) => {
      if (garden.id === gardenId) {
        garden.plants.every((plant) => {
          if (plant.id === entry.PlantId) {
            const index = plant.entries.findIndex(
              (anEntry) => anEntry.id === entry.id,
            );
            plant.entries[index] = entry;
            return false;
          }
          return true;
        });
        return false;
      }
      return true;
    });
    return newUserData;
  },

  deleteEntryFromPlantInGardenInUserData(entry, gardenId, userData) {
    const newUserData = clone(userData);
    sessionStorage.removeItem('userData');
    newUserData.gardens.every((garden) => {
      if (garden.id === gardenId) {
        garden.plants.every((plant) => {
          if (plant.id === entry.PlantId) {
            plant.entries = plant.entries.filter(
              (anEntry) => anEntry.id !== entry.id,
            );
            return false;
          }
          return true;
        });
        return false;
      }
      return true;
    });
    return newUserData;
  },

  addImageToObjectInUserData(image, userData) {
    const newUserData = clone(userData);
    sessionStorage.removeItem('userData');
    if (image.GardenId > 0) {
      newUserData.gardens.every((garden) => {
        if (garden.id === parseInt(image.GardenId, 0)) {
          if (!garden.images) garden.images = [];
          garden.images.push(image);
          return false;
        }
        return true;
      });
      return newUserData;
    }

    if (image.PlantId > 0) {
      let found = false;
      newUserData.gardens.every((garden) => {
        garden.plants.every((plant) => {
          if (plant.id === parseInt(image.PlantId, 0)) {
            if (!plant.images) plant.images = [];
            plant.images.push(image);
            found = true;
            return false;
          }
          return true;
        });
        return !found;
      });
      return newUserData;
    }

    if (image.EntryId > 0) {
      let found = false;
      newUserData.gardens.every((garden) => {
        garden.plants.every((plant) => {
          plant.entries.every((entry) => {
            if (entry.id === parseInt(image.EntryId, 0)) {
              if (!entry.images) entry.images = [];
              entry.images.push(image);
              found = true;
              return false;
            }
            return true;
          });
          return !found;
        });
        return !found;
      });
      return newUserData;
    }

    throw new Error('invalid image');
  },

  removeImageFromObjectInUserData(image, userData) {
    const newUserData = clone(userData);
    sessionStorage.removeItem('userData');
    if (image.GardenId > 0) {
      newUserData.gardens.every((garden) => {
        if (garden.id === parseInt(image.GardenId, 0)) {
          garden.images = garden.images.filter(
            (anImage) => anImage.id !== image.id,
          );
          return false;
        }
        return true;
      });
      return newUserData;
    }

    if (image.PlantId > 0) {
      let found = false;
      newUserData.gardens.every((garden) => {
        garden.plants.every((plant) => {
          if (plant.id === parseInt(image.PlantId, 0)) {
            plant.images = plant.images.filter(
              (anImage) => anImage.id !== image.id,
            );
            found = true;
            return false;
          }
          return true;
        });
        return !found;
      });
      return newUserData;
    }

    if (image.EntryId > 0) {
      let found = false;
      newUserData.gardens.every((garden) => {
        garden.plants.every((plant) => {
          plant.entries.every((entry) => {
            if (entry.id === parseInt(image.EntryId, 0)) {
              entry.images = entry.images.filter(
                (anImage) => anImage.id !== image.id,
              );
              found = true;
              return false;
            }
            return true;
          });
          return !found;
        });
        return !found;
      });
      return newUserData;
    }

    throw new Error('invalid image');
  },
};
