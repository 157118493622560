export const ADD_ENTRY = 'ADD_ENTRY';
export const UPDATE_ENTRY = 'UPDATE_ENTRY';
export const DELETE_ENTRY = 'DELETE_ENTRY';

export const addEntry = (entry, gardenId) => ({
  type: ADD_ENTRY,
  entry,
  gardenId,
});

export const updateEntry = (entry, gardenId) => ({
  type: UPDATE_ENTRY,
  entry,
  gardenId,
});

export const deleteEntry = (entry, gardenId) => ({
  type: DELETE_ENTRY,
  entry,
  gardenId,
});
