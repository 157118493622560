import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types/prop-types';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import { push } from 'connected-react-router';
import Badge from '@material-ui/core/Badge';
import Dialog from '@material-ui/core/Dialog';
import classNames from 'classnames';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { deleteGarden } from '../../actions/Gardens';
import globalStyles from '../../styles';
import GardenAPI from '../../resources/garden';
import apiUtils from '../../resources/utils';
import utils from '../../utils';
import AlertDialog from '../Shared/Confirmation';
import cabbage from './assets/cabbage.png';
import EditGarden from './EditGarden';
import EditableImage from '../Shared/EditableImage';
import imageAPI from '../../resources/image';
import { addImage } from '../../actions/Images';
import ImageDialog from '../Shared/ImageDialog';

const styles = (theme) => ({
  ...globalStyles(theme),
  card: {
    margin: 10,
  },
  media: {
    maxWidth: '80%',
    height: 180,
    margin: 'auto',
  },
  badge: {
    margin: theme.spacing.unit,
    right: !-10,
  },
});

class Garden extends React.Component {
  state = {
    deleteModalOpen: false,
    editModalOpen:
      // eslint-disable-next-line react/destructuring-assignment
      this.props.router.location.search === `?edit=${this.props.garden.id}`,
    imagesModalOpen:
      // eslint-disable-next-line react/destructuring-assignment
      this.props.router.location.search === `?images=${this.props.garden.id}`,
  };

  handlePlantsClick = () => {
    const { dispatch, garden } = this.props;
    return dispatch(push(`/gardens/${garden.id}/plants`));
  };

  handleDeleteModalClose = async (deleteModel) => {
    if (deleteModel) {
      const { garden, dispatch } = this.props;
      try {
        await GardenAPI.deleteGarden(garden);
      } catch (err) {
        apiUtils.handleLoginResponse(err, dispatch);
      }
      dispatch(deleteGarden(garden));
    }

    this.setState({ deleteModalOpen: false });
  };

  handleEditModalClose = () => {
    const { dispatch } = this.props;
    this.setState({ editModalOpen: false });
    return dispatch(push('/gardens'));
  };

  handleDeleteClick = () => {
    this.setState({ deleteModalOpen: true });
  };

  handleEditClick = () => {
    const { dispatch, garden } = this.props;
    this.setState({ editModalOpen: true });
    return dispatch(push(`/gardens?edit=${garden.id}`));
  };

  handleImagesClick = () => {
    const { dispatch, garden } = this.props;
    this.setState({
      imagesModalOpen: true,
    });
    return dispatch(push(`gardens?images=${garden.id}`));
  };

  handleImagesModalClose = () => {
    const { dispatch } = this.props;
    this.setState({ imagesModalOpen: false });
    return dispatch(push('gardens'));
  };

  handleDrop = async (accepted) => {
    const { garden, dispatch } = this.props;

    if (accepted.length > 0) {
      const image = accepted[0];
      image.GardenId = garden.id;
      let response;
      try {
        response = await imageAPI.addImage(image);
      } catch (ex) {
        return null;
      }

      return dispatch(addImage(response.data));
    }
    return null;
  };

  render() {
    const { garden, classes } = this.props;
    const { editModalOpen, imagesModalOpen, deleteModalOpen } = this.state;

    return (
      <div>
        <AlertDialog
          agree="Delete"
          body={`Are you sure you want to delete garden: ${garden.name}?`}
          closeAction={this.handleDeleteModalClose}
          disagree="Cancel"
          open={deleteModalOpen}
          title="Delete Garden?"
        />
        <Dialog open={editModalOpen} onClose={this.handleEditModalClose}>
          <EditGarden garden={garden} handleClose={this.handleEditModalClose} />
        </Dialog>
        <ImageDialog
          open={imagesModalOpen}
          onClose={this.handleImagesModalClose}
          images={garden.images}
        />
        <Card className={classes.card}>
          <CardActionArea>
            <EditableImage handleDrop={this.handleDrop}>
              <CardMedia
                className={classNames(classes.media, classes.textAlignCenter)}
                image={
                  garden.images && garden.images.length > 0
                    ? garden.images[0].path
                    : cabbage
                }
                title={utils.toSentenceCase(garden.name)}
              />
              <CardContent>
                <Typography variant="h4">{garden.name}</Typography>
                <Typography variant="h6">{garden.description}</Typography>
              </CardContent>
            </EditableImage>
          </CardActionArea>
          <CardActions>
            <Badge
              badgeContent={garden && garden.plants ? garden.plants.length : 0}
              className={classes.badge}
              showZero
              color="primary"
            >
              <Button
                onClick={this.handlePlantsClick}
                size="small"
                color="primary"
              >
                Plants
              </Button>
            </Badge>
            <Button onClick={this.handleEditClick} size="small" color="primary">
              Edit
            </Button>
            <Badge
              badgeContent={garden && garden.images ? garden.images.length : 0}
              className={classes.badge}
              showZero
              color="primary"
            >
              <Button
                onClick={this.handleImagesClick}
                size="small"
                color="primary"
              >
                Images
              </Button>
            </Badge>
            <Button
              onClick={this.handleDeleteClick}
              size="small"
              color="primary"
            >
              Delete
            </Button>
          </CardActions>
        </Card>
      </div>
    );
  }
}

Garden.propTypes = {
  garden: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { user: theUser, app, router } = state;
  const { isLoggedIn, userData } = theUser;
  const { isFetching } = app;

  return {
    isLoggedIn,
    userData,
    isFetching,
    router,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(Garden));
