import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types/prop-types';

class AlertDialog extends React.Component {
  state = {};

  handleClose = (closeProp) => () => {
    const { closeAction } = this.props;
    closeAction(closeProp);
  };

  render() {
    const { open, body, title, agree, disagree } = this.props;

    return (
      <div>
        <Dialog
          open={open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {body}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose(false)} color="primary">
              {disagree}
            </Button>
            <Button onClick={this.handleClose(true)} color="primary" autoFocus>
              {agree}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

AlertDialog.defaultProps = {
  open: false,
  agree: 'Agree',
  disagree: 'Disagree',
};

AlertDialog.propTypes = {
  open: PropTypes.bool,
  closeAction: PropTypes.func.isRequired,
  body: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  agree: PropTypes.string,
  disagree: PropTypes.string,
};

export default AlertDialog;
