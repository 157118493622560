import axios from 'axios';
import config from './config';

const { apiURL } = config;

export default {
  loginWithToken(aToken) {
    return axios.post(
      `${apiURL}/login`,
      {},
      {
        headers: {
          'x-access-token': aToken,
        },
      },
    );
  },
  loginWithUser(aUser) {
    return axios.post(`${apiURL}/login`, aUser);
  },
};
