import React from 'react';
import { render } from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { createBrowserHistory } from 'history';
import { routerMiddleware, push } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import Cookies from 'universal-cookie';
import { init as initAPM } from '@elastic/apm-rum';

import rootReducer from './reducers';
import Routes from './routes';
import * as serviceWorker from './serviceWorker';
import LoginAPI from './resources/login';
import { getUserData, loginAttempted, userLoggedIn } from './actions/Users';

initAPM({
  serviceName: 'gardentracker-ui',
  serverUrl:
      process.env.REACT_APP_ELASTIC_APM_SERVER_URL || 'https://apm.dannyshay.com',
  environment: process.env.NODE_ENV || 'development',
});

const history = createBrowserHistory();
const cookies = new Cookies();
const middleware = [thunk, routerMiddleware(history)];
if (process.env.NODE_ENV !== 'production') {
  middleware.push(createLogger());
}

const store = createStore(
  rootReducer(history),
  composeWithDevTools(applyMiddleware(...middleware)),
);

const attemptLoginWithToken = async (token) => {
  const results = await LoginAPI.loginWithToken(token);
  store.dispatch(loginAttempted());
  const response = results.data;
  if (response.success) {
    const { user } = response;
    // noinspection JSCheckFunctionSignatures
    store.dispatch(userLoggedIn(user));
    store.dispatch(getUserData(user.id));

    // User is logged in and trying to go back to the login page
    if (
      ['/', '/signup', '/verified', '/resetPassword'].includes(
        window.location.pathname,
      )
    ) {
      store.dispatch(push('/gardens'));
      return null;
    }

    // Unauthorized route
    if (window.location.pathname === '/admin' && user.role !== 'admin') {
      store.dispatch(push('/'));
    }
  } else {
    cookies.remove('accessToken', {
      path: '/',
      domain:
        process.env.NODE_ENV === 'production' ? 'gardentracker.app' : null,
    });
    store.dispatch(push('/'));
  }
  return null;
};

const token = cookies.get('accessToken');
if (token) {
  // noinspection JSIgnoredPromiseFromCall
  attemptLoginWithToken(token);
} else if (
  // Whitelisted URLs
  !['/', '/signup', '/verified', '/resetPassword'].includes(
    window.location.pathname,
  )
) {
  store.dispatch(loginAttempted());
  store.dispatch(push('/'));
} else {
  store.dispatch(loginAttempted());
}

render(
  <Provider store={store}>
    <Routes history={history} />
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
