import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
// noinspection ES6CheckImport
import queryString from 'query-string';
import UserAPI from '../../../resources/user';
import styles from '../../../styles';

class Reset extends React.Component {
  state = { password: '', confirmPassword: '' };

  componentDidMount() {
    const { location } = this.props;
    const { search } = location;

    const { userId, token } = queryString.parse(search);

    return this.setState({ userId, token });
  }

  resetPassword = async () => {
    const { userId, token } = this.state;

    this.setState({
      submitClicked: true,
      errorField: null,
      error: null,
      message: null,
    });
    const { password, confirmPassword } = this.state;
    if (!password || password.length === 0) {
      return this.setState({
        errorField: 'password',
        error: 'Please complete all required fields',
      });
    }

    if (!confirmPassword || confirmPassword.length === 0) {
      return this.setState({
        errorField: 'confirmPassword',
        error: 'Please complete all required fields',
      });
    }

    if (password !== confirmPassword) {
      return this.setState({
        error: 'Passwords must match',
      });
    }

    const response = await UserAPI.updateUser(
      { id: userId, hash: password },
      token,
    );

    if (response.successful) {
      return this.setState({ message: 'Password reset successfully!' });
    }

    return this.setState({ error: response.message });
  };

  render() {
    const { classes } = this.props;
    const {
      submitClicked,
      errorField,
      password,
      confirmPassword,
      error,
      message,
    } = this.state;

    return (
      <Grid className={classes.grid} align="center" container spacing={24}>
        <Grid item xs={12}>
          <h1>Reset Your Password</h1>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="password"
            name="password"
            className={classes.textField}
            value={password}
            onChange={(e) => this.setState({ password: e.target.value })}
            margin="normal"
            error={submitClicked && errorField === 'password'}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="confirm password"
            name="confirmPassword"
            className={classes.textField}
            value={confirmPassword}
            onChange={(e) => this.setState({ confirmPassword: e.target.value })}
            margin="normal"
            error={submitClicked && errorField === 'confirmPassword'}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={this.resetPassword}
          >
            Reset
          </Button>
        </Grid>
        <Grid item xs={12}>
          <span className={classes.error}>{error}</span>
          <span className={classes.success}>{message}</span>
        </Grid>
      </Grid>
    );
  }
}

Reset.propTypes = {
  classes: propTypes.object.isRequired,
  location: propTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { user: theUser, app } = state;
  const { isLoggedIn, user } = theUser;
  const { isFetching } = app;

  return {
    isLoggedIn,
    user,
    isFetching,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(Reset));
