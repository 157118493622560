import React from 'react';
import PropTypes from 'prop-types/prop-types';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { connect } from 'react-redux';
import classNames from 'classnames';
import UserAPI from '../../resources/user';
import utils from '../../utils';
import { setLoading } from '../../actions/Shared';
import Loader from '../Shared/Loader';
import styles from '../../styles';

const cookies = new Cookies();

class Signup extends React.Component {
  state = {
    rememberMe: false,
    username: '',
    hash: '',
    email: '',
  };

  initialize = () => {
    this.setState({
      error: null,
      errorField: null,
      success: null,
      submitClicked: false,
      rememberMe: false,
      username: '',
      hash: '',
      email: '',
    });
  };

  handleChange = (event) => {
    this.setState({ error: null });
    const { name, value, checked } = event.target;
    if (name === 'rememberMe') {
      this.setState({ [name]: checked });
    } else {
      this.setState({
        [name]: value,
      });
    }
  };

  validateForm = () => {
    const { username, hash, email } = this.state;

    return username && hash && email;
  };

  handleErrors = (errorResponse) => {
    let state = { ...this.state };
    const { dispatch } = this.props;
    dispatch(setLoading(false));
    switch (errorResponse.status) {
      case 400:
        // noinspection JSUnresolvedVariable
        const errorField = errorResponse.data.errors[0].field;
        let error = errorResponse.data.errors[0].message;

        switch (errorField) {
          case 'email':
            if (error.includes('isEmail')) {
              error = 'Invalid email address';
            } else {
              error =
                'Sorry...there is already an account using that email address';
            }

            break;
          case 'username':
            error = 'Sorry that username is taken...try a different one';
            break;
          case 'hash':
            error = 'Invalid password';
            break;
          default:
            error = utils.toSentenceCase(errorResponse.data.errors[0].message);
            break;
        }

        state = { ...state, errorField, error };
        break;
      case 403:
        // We aren't supposed to get a 401 here...likely something is going wrong with credentials
        state.error = 'Hmmm...something went wrong. Please try again.';
        break;
      case 500:
        state.error = `Something went wrong - but don${"'"}t worry, our team is checking it out`;
        break;
      default:
        state.error = `Something went wrong - but don${"'"}t worry, our team is checking it out`;
        break;
    }

    cookies.remove('accessToken', {
      path: '/',
      domain:
        process.env.NODE_ENV === 'production' ? 'gardentracker.app' : null,
    });

    this.setState(state);
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({
      submitClicked: true,
      error: null,
      errorField: null,
      success: null,
    });

    const { dispatch } = this.props;

    if (this.validateForm()) {
      let response;
      dispatch(setLoading(true));
      try {
        response = await UserAPI.addUser(this.state);
        dispatch(setLoading(false));
      } catch (err) {
        this.handleErrors(err.response);
        return;
      }

      if (!response || !response.data) {
        this.setState({
          error: 'Sorry...an error occurred. Please try again.',
        });
        return;
      }

      this.setState({ success: 'Check your email!' });
    } else {
      this.setState({ error: 'Please check your entry and try again' });
    }
  };

  handleErrorAndSuccessMessage() {
    const { classes } = this.props;
    const { error, success } = this.state;

    if (error || success) {
      return (
        <Grid item xs={12}>
          <div className={error ? classes.error : classes.success}>
            {error || success}
          </div>
        </Grid>
      );
    }

    return null;
  }

  render() {
    const { classes, isFetching } = this.props;
    const { username, hash, errorField, submitClicked, email } = this.state;

    if (isFetching) {
      return (
        <Grid
          container
          spacing={24}
          align="center"
          direction="column"
          className={classNames(classes.grid, classes.padded)}
        >
          <Grid item xs={12}>
            <Loader />
          </Grid>
        </Grid>
      );
    }

    return (
      <div className={classes.root}>
        <form className={classes.container} noValidate autoComplete="off">
          <Grid
            className={classNames(classes.grid, classes.padded)}
            direction="column"
            align="center"
            container
            spacing={24}
          >
            <Grid item xs={12}>
              <h1 className={classes.title}>
                Create a new Gardentracker account
              </h1>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="email"
                className={classes.textField}
                value={email}
                name="email"
                onChange={this.handleChange}
                margin="normal"
                error={submitClicked && (!email || errorField === 'email')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="username"
                className={classes.textField}
                value={username}
                name="username"
                onChange={this.handleChange}
                margin="normal"
                error={
                  submitClicked && (!username || errorField === 'username')
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="password"
                className={classes.textField}
                value={hash}
                type="password"
                name="hash"
                onChange={this.handleChange}
                error={submitClicked && (!hash || errorField === 'hash')}
                margin="normal"
              />
            </Grid>
            {this.handleErrorAndSuccessMessage()}
            <Grid item xs={12}>
              <Button
                onClick={this.handleSubmit}
                variant="contained"
                className={classes.button}
                color="primary"
                type="submit"
              >
                Sign Up
              </Button>
              or{' '}
              <Button
                component={Link}
                to="/"
                onClick={this.initialize}
                variant="outlined"
                color="primary"
                className={classes.button}
              >
                Home
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    );
  }
}

Signup.propTypes = {
  classes: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { user: theUser, app } = state;
  const { isLoggedIn, user } = theUser;
  const { isFetching } = app;

  return {
    isLoggedIn,
    user,
    isFetching,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(Signup));
