import beet from './assets/beat.png';
import blueberry from './assets/blueberry.png';
import broccoli from './assets/broccoli.png';
import cabbage from './assets/cabbage.png';
import carrot from './assets/carrot.png';
import cauliflower from './assets/cauliflower.png';
import corn from './assets/corn.png';
import cucumber from './assets/cucumber.png';
import edamame from './assets/edamame.png';
import eggplant from './assets/eggplant.png';
import herbs from './assets/herbs.png';
import lettuce from './assets/lettuce.png';
import onion from './assets/onion.png';
import pepper from './assets/pepper.png';
import radish from './assets/radish.png';
import squash from './assets/squash.png';
import strawberry from './assets/strawberry.png';
import tomato from './assets/tomato.png';
import watermelon from './assets/watermelon.png';
import zucchini from './assets/zucchini.png';

export default {
  getImageForPlant: (plant) => {
    if (plant.images && plant.images.length > 0) {
      return plant.images[0].path;
    }

    const name = plant.name.toLowerCase();

    if (name.includes('cabbage')) {
      return cabbage;
    }

    if (name.includes('lettuce')) {
      return lettuce;
    }

    if (name.includes('beet')) {
      return beet;
    }

    if (name.includes('blueberr')) {
      return blueberry;
    }

    if (name.includes('broccoli')) {
      return broccoli;
    }

    if (name.includes('carrot')) {
      return carrot;
    }

    if (name.includes('cauliflower')) {
      return cauliflower;
    }

    if (name.includes('corn')) {
      return corn;
    }

    if (name.includes('cucumber')) {
      return cucumber;
    }

    if (name.includes('edamame')) {
      return edamame;
    }

    if (name.includes('eggplant')) {
      return eggplant;
    }

    if (name.includes('herbs')) {
      return herbs;
    }
    if (name.includes('onion')) {
      return onion;
    }

    if (name.includes('pepper')) {
      return pepper;
    }

    if (name.includes('radish')) {
      return radish;
    }

    if (name.includes('squash')) {
      return squash;
    }

    if (name.includes('strawberr')) {
      return strawberry;
    }

    if (name.includes('watermelon')) {
      return watermelon;
    }

    if (name.includes('zucchini')) {
      return zucchini;
    }

    if (name.includes('tomato')) {
      return tomato;
    }

    // Default Image
    return herbs;
  },
};
