import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types/prop-types';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Fade from '@material-ui/core/Fade';
import classnames from 'classnames';
import styles from '../../styles/index';
import Loader from '../Shared/Loader';
import PlantAPI from '../../resources/plant';
import { addPlant, updatePlant } from '../../actions/Plants/index';
import utils from '../../resources/utils';

class EditPlant extends React.Component {
  // eslint-disable-next-line react/destructuring-assignment
  state = { plant: this.props.plant };

  handleSubmit = async (e) => {
    e.preventDefault();

    this.setState({
      submitClicked: true,
      error: null,
      errorField: null,
      success: null,
    });
    const { dispatch, garden, handleClose } = this.props;

    if (this.validateForm()) {
      const { plant } = this.state;
      if (!plant.GardenId) {
        plant.GardenId = garden.id;
      }

      let response;
      try {
        response = !plant.id
          ? await PlantAPI.addPlant(plant)
          : await PlantAPI.updatePlant(plant);
      } catch (err) {
        utils.handleLoginResponse(err, dispatch);

        return this.setState({ error: err.response.data.message });
      }

      if (!plant.id) {
        dispatch(addPlant(response.data));
      }
      dispatch(updatePlant(response.data));

      return handleClose();
    }

    this.setState({ error: 'Please check your entry and try again.' });
    return null;
  };

  handleChange = (event) => {
    this.setState({ error: null, errorField: null, success: null });
    const { plant } = this.state;
    const { name, value, checked } = event.target;

    if (name === 'active') {
      plant[name] = checked;
    } else {
      plant[name] = value;
    }

    this.setState({ plant });
  };

  validateForm = () => {
    const { plant } = this.state;
    return plant.name && plant.quantity && plant.location;
  };

  render() {
    const { isFetching, classes, userData } = this.props;

    const {
      submitClicked,
      errorField,
      error,
      success,
      plant,
      showSuccess,
    } = this.state;

    if (isFetching || !userData) {
      return (
        <Grid
          className={classes.paddedTop24}
          container
          align="center"
          justify="center"
          spacing={24}
        >
          <Loader />
        </Grid>
      );
    }

    if (!plant) {
      return (
        <Grid
          className={classes.paddedTop24}
          container
          spacing={24}
          justify="center"
          align="center"
        >
          <div>No plant found</div>
        </Grid>
      );
    }

    return (
      <div className={classnames(classes.root, classes.paddedBottom16)}>
        <form className={classes.container} noValidate autoComplete="off">
          <Grid
            container
            spacing={24}
            align="center"
            justify="center"
            className={classes.grid}
          >
            <Paper className={classes.paper}>
              <Grid item xs={12}>
                <h3>
                  {plant.id > 0 ? 'Edit' : 'Add'} Plant {plant.id}
                </h3>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="name"
                  className={classes.textField}
                  value={plant.name}
                  name="name"
                  onChange={this.handleChange}
                  margin="normal"
                  error={
                    submitClicked && (!plant.name || errorField === 'name')
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="quantity"
                  name="quantity"
                  type="number"
                  className={classes.textField}
                  value={plant.quantity}
                  onChange={this.handleChange}
                  margin="normal"
                  error={
                    submitClicked &&
                    (!plant.quantity || errorField === 'quantity')
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="location"
                  name="location"
                  className={classes.textField}
                  value={plant.location}
                  onChange={this.handleChange}
                  margin="normal"
                  error={
                    submitClicked &&
                    (!plant.location || errorField === 'location')
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="date"
                  name="date"
                  type="date"
                  className={classes.textField}
                  value={plant.date}
                  onChange={this.handleChange}
                  margin="normal"
                  error={
                    submitClicked && (!plant.date || errorField === 'date')
                  }
                />
              </Grid>
              {error ? (
                <Grid item xs={12}>
                  <div className={classes.error}>{error}</div>
                </Grid>
              ) : null}
              <Fade in={showSuccess}>
                <Grid item xs={12}>
                  <div className={classes.success}>{success}</div>
                </Grid>
              </Fade>
              <Grid item xs={12}>
                <Button
                  onClick={this.handleSubmit}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  {plant.id > 0 ? 'Save' : 'Add'} Plant
                </Button>
              </Grid>
            </Paper>
          </Grid>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { user: theUser, app, router } = state;
  const { isLoggedIn, userData } = theUser;
  const { isFetching } = app;
  const { location } = router;
  const { pathname } = location;

  return {
    isLoggedIn,
    userData,
    isFetching,
    router,
    pathname,
  };
};

EditPlant.defaultProps = {
  userData: null,
};

EditPlant.propTypes = {
  classes: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  userData: PropTypes.object,
  plant: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  garden: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(withStyles(styles)(EditPlant));
