import axios from 'axios';
import Cookies from 'universal-cookie';
import config from './config';

const cookies = new Cookies();

const { apiURL } = config;

export default {
  addPlant(aPlant) {
    return axios.post(`${apiURL}/plants`, aPlant, {
      headers: {
        'x-access-token': cookies.get('accessToken'),
      },
    });
  },

  updatePlant(aPlant) {
    return axios.put(`${apiURL}/plants/${aPlant.id}`, aPlant, {
      headers: {
        'x-access-token': cookies.get('accessToken'),
      },
    });
  },

  deletePlant(aPlant) {
    return axios.delete(`${apiURL}/plants/${aPlant.id}`, {
      headers: {
        'x-access-token': cookies.get('accessToken'),
      },
    });
  },
};
