import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import userReducer from './user';
import appReducer from './app';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    app: appReducer,
    user: userReducer,
  });
