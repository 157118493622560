import axios from 'axios';
import Cookies from 'universal-cookie';
import config from './config';

const cookies = new Cookies();

const { apiURL } = config;

export default {
  addGarden(aGarden) {
    return axios.post(`${apiURL}/gardens`, aGarden, {
      headers: {
        'x-access-token': cookies.get('accessToken'),
      },
    });
  },

  updateGarden(aGarden) {
    return axios.put(`${apiURL}/gardens/${aGarden.id}`, aGarden, {
      headers: {
        'x-access-token': cookies.get('accessToken'),
      },
    });
  },

  deleteGarden(aGarden) {
    return axios.delete(`${apiURL}/gardens/${aGarden.id}`, {
      headers: {
        'x-access-token': cookies.get('accessToken'),
      },
    });
  },
};
