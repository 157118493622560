export const ADD_GARDEN = 'ADD_GARDEN';
export const UPDATE_GARDEN = 'UPDATE_GARDEN';
export const DELETE_GARDEN = 'DELETE_GARDEN';

export const addGarden = (garden) => ({
  type: ADD_GARDEN,
  garden,
});

export const updateGarden = (garden) => ({
  type: UPDATE_GARDEN,
  garden,
});

export const deleteGarden = (garden) => ({
  type: DELETE_GARDEN,
  garden,
});
