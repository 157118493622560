export const ADD_PLANT = 'ADD_PLANT';
export const UPDATE_PLANT = 'UPDATE_PLANT';
export const DELETE_PLANT = 'DELETE_PLANT';

export const addPlant = (plant) => ({
  type: ADD_PLANT,
  plant,
});

export const updatePlant = (plant) => ({
  type: UPDATE_PLANT,
  plant,
});

export const deletePlant = (plant) => ({
  type: DELETE_PLANT,
  plant,
});
