import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dropzone from 'react-dropzone';
import globalStyles from '../../styles';

const styles = (theme) => ({
  ...globalStyles(theme),
  noBorder: {
    border: 'none',
  },
});

const EditableImage = ({ children, handleDrop }) => (
  <Dropzone
    onDrop={handleDrop}
    accept="image/jpeg, image/jpg, image/png, image/gif"
  >
    {({ getRootProps, getInputProps }) => (
      <section>
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          {children}
        </div>
      </section>
    )}
  </Dropzone>
);

EditableImage.defaultProps = {
  children: null,
};

EditableImage.propTypes = {
  classes: PropTypes.object.isRequired,
  handleDrop: PropTypes.func.isRequired,
  children: PropTypes.array,
};

export default withStyles(styles)(EditableImage);
