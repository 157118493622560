import React from 'react';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types/prop-types';
import Grid from '@material-ui/core/es/Grid/Grid';
import Typography from '@material-ui/core/es/Typography/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import classNames from 'classnames';
import Loader from '../Shared/Loader';
import globalStyles from '../../styles';
import Garden from './Garden';
import EditGarden from './EditGarden';

const styles = (theme) => ({
  ...globalStyles(theme),
});

class Gardens extends React.Component {
  state = { addModalOpen: false };

  handleAddClick = () => {
    this.setState({ addModalOpen: true });
  };

  handleAddModalClose = () => {
    this.setState({ addModalOpen: false });
  };

  getEditDialog = () => {
    const { addModalOpen } = this.state;
    return (
      <Dialog open={addModalOpen} onClose={this.handleAddModalClose}>
        <EditGarden
          garden={{
            name: '',
            description: '',
          }}
          handleClose={this.handleAddModalClose}
        />
      </Dialog>
    );
  };

  render() {
    const { userData, isLoggedIn, classes } = this.props;

    if (!isLoggedIn || !userData || !userData.gardens) {
      return (
        <Grid container spacing={24} justify="center" align="center">
          <Grid item>
            <Loader />
          </Grid>
        </Grid>
      );
    }

    const { gardens } = userData;

    if (gardens.length === 0) {
      return (
        <div className={classes.root}>
          {this.getEditDialog()}
          <Grid container spacing={24} justify="center" align="center">
            <Grid item xs={12}>
              <Typography className={classes.paddedTop20} variant="display1">
                No gardens added
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={this.handleAddClick}
                className={classNames(classes.addButton, classes.noFloat)}
              >
                Add Garden
              </Button>
            </Grid>
          </Grid>
        </div>
      );
    }

    return (
      <div className={classes.root}>
        {this.getEditDialog()}
        <Grid
          align="center"
          justify="space-evenly"
          container
          spacing={24}
          className={classes.grid}
        >
          <Grid item xs={12}>
            <Button
              className={classNames(classes.addButton, classes.noFloat)}
              variant="contained"
              onClick={this.handleAddClick}
            >
              <Typography className={classes.white} variant="subtitle2">
                Add Garden
              </Typography>
            </Button>
          </Grid>
          {gardens.map((garden) => (
            <Grid item key={garden.id}>
              <Garden garden={Object.assign({}, garden)} />
            </Grid>
          ))}
        </Grid>
      </div>
    );
  }
}

Gardens.defaultProps = {
  userData: null,
  isLoggedIn: null,
};

Gardens.propTypes = {
  userData: PropTypes.object,
  isLoggedIn: PropTypes.bool,
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { user: theUser, app } = state;
  const { isLoggedIn, userData } = theUser;
  const { isFetching } = app;

  return {
    isLoggedIn,
    userData,
    isFetching,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(Gardens));
