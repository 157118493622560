import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types/prop-types';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import { push } from 'connected-react-router';
import moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import classnames from 'classnames';
import Badge from '@material-ui/core/Badge';
import { deleteEntry } from '../../actions/Entries';
import globalStyles from '../../styles';
import EntryAPI from '../../resources/entry';
import apiUtils from '../../resources/utils';
import utils from '../../utils';
import AlertDialog from '../Shared/Confirmation';
import cabbage from './assets/cabbage.png';
import EditEntry from './EditEntry';
import imageAPI from '../../resources/image';
import { addImage } from '../../actions/Images';
import EditableImage from '../Shared/EditableImage';
import ImageDialog from '../Shared/ImageDialog';

const styles = (theme) => ({
  ...globalStyles(theme),
  media: {
    maxWidth: '80%',
    height: 195,
    width: 235,
    margin: 'auto',
  },
  badge: {
    margin: theme.spacing.unit,
    right: !-10,
  },
});

class Entry extends React.Component {
  state = {
    deleteModalOpen: false,
    editModalOpen:
      // eslint-disable-next-line react/destructuring-assignment
      this.props.router.location.search === `?edit=${this.props.entry.id}`,
    imagesModalOpen:
      // eslint-disable-next-line react/destructuring-assignment
      this.props.router.location.search === `?images=${this.props.entry.id}`,
  };

  handleDeleteModalClose = async (deleteModel) => {
    if (deleteModel) {
      const { entry, garden, dispatch } = this.props;
      try {
        await EntryAPI.deleteEntry(entry);
      } catch (err) {
        apiUtils.handleLoginResponse(err, dispatch);
      }
      dispatch(deleteEntry(entry, garden.id));
    }

    this.setState({ deleteModalOpen: false });
  };

  handleEditModalClose = () => {
    const { dispatch, garden, plant } = this.props;
    this.setState({ editModalOpen: false });
    return dispatch(push(`/gardens/${garden.id}/plants/${plant.id}/entries`));
  };

  handleDeleteClick = () => {
    this.setState({ deleteModalOpen: true });
  };

  handleEditClick = () => {
    const { dispatch, garden, plant, entry } = this.props;
    this.setState({ editModalOpen: true });
    return dispatch(
      push(`/gardens/${garden.id}/plants/${plant.id}/entries?edit=${entry.id}`),
    );
  };

  handleImagesClick = () => {
    const { dispatch, garden } = this.props;
    this.setState({
      imagesModalOpen: true,
    });
    return dispatch(push(`entries?images=${garden.id}`));
  };

  handleImagesModalClose = () => {
    const { dispatch } = this.props;
    this.setState({ imagesModalOpen: false });
    return dispatch(push('entries'));
  };

  handleDrop = async (accepted) => {
    const { entry, dispatch } = this.props;

    if (accepted.length > 0) {
      const image = accepted[0];
      image.EntryId = entry.id;
      let response;
      try {
        response = await imageAPI.addImage(image);
      } catch (ex) {
        return null;
      }

      return dispatch(addImage(response.data));
    }
    return null;
  };

  render() {
    const { entry, classes, garden, plant } = this.props;
    const { imagesModalOpen, editModalOpen, deleteModalOpen } = this.state;

    entry.date = moment.utc(entry.date).format('YYYY-MM-DD');

    const entryAlert = `Are you sure you want to delete ${entry.quantity} x ${entry.size} entry from: ${entry.date}?`;

    return (
      <div>
        <AlertDialog
          agree="Delete"
          body={entryAlert}
          closeAction={this.handleDeleteModalClose}
          disagree="Cancel"
          open={deleteModalOpen}
          title="Delete Entry?"
        />
        <Dialog open={editModalOpen} onClose={this.handleEditModalClose}>
          <EditEntry
            garden={garden}
            plant={plant}
            entry={entry}
            handleClose={this.handleEditModalClose}
          />
        </Dialog>
        <ImageDialog
          open={imagesModalOpen}
          onClose={this.handleImagesModalClose}
          images={entry.images}
        />
        <Card className={classes.card}>
          <CardActionArea>
            <EditableImage handleDrop={this.handleDrop}>
              <CardMedia
                className={classnames(classes.media, classes.textAlignCenter)}
                image={
                  entry.images && entry.images.length > 0
                    ? entry.images[0].path
                    : cabbage
                }
                title={utils.toSentenceCase(entry.size)}
              />
              <CardContent>
                <Typography variant="h6">
                  {entry.quantity} x {utils.toSentenceCase(entry.size)}
                </Typography>
                <Typography variant="body2">
                  {moment.utc(entry.date).format('MMMM Do, YYYY')}
                </Typography>
              </CardContent>
            </EditableImage>
          </CardActionArea>
          <CardActions>
            <Button onClick={this.handleEditClick} size="small" color="primary">
              Edit
            </Button>
            <Badge
              badgeContent={entry && entry.images ? entry.images.length : 0}
              className={classes.badge}
              showZero
              color="primary"
            >
              <Button
                onClick={this.handleImagesClick}
                size="small"
                color="primary"
              >
                Images
              </Button>
            </Badge>
            <Button
              onClick={this.handleDeleteClick}
              size="small"
              color="primary"
            >
              Delete
            </Button>
          </CardActions>
        </Card>
      </div>
    );
  }
}

Entry.propTypes = {
  entry: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
  garden: PropTypes.object.isRequired,
  plant: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { user: theUser, app, router } = state;
  const { isLoggedIn, userData } = theUser;
  const { isFetching } = app;

  return {
    isLoggedIn,
    userData,
    isFetching,
    router,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(Entry));
