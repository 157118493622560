import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
});

const Content = () => (
  <Grid container direction="column" align="center">
    <Grid item xs={12}>
      <h1>Page not found</h1>
    </Grid>
  </Grid>
);

Content.propTypes = {};

export default withStyles(styles)(Content);
