import React from 'react';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import ReactRouterPropTypes from 'react-router-prop-types';
import PropTypes from 'prop-types/prop-types';
import Grid from '@material-ui/core/es/Grid/Grid';
import Typography from '@material-ui/core/es/Typography/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import moment from 'moment';
import classNames from 'classnames';
import Loader from '../Shared/Loader';
import styles from '../../styles';
import Entry from './Entry';
import EditEntry from './EditEntry';

class Entries extends React.Component {
  state = { addModalOpen: false };

  handleAddClick = () => {
    this.setState({ addModalOpen: true });
  };

  handleAddModalClose = () => {
    this.setState({ addModalOpen: false });
  };

  handleGardenClick = () => {
    const { dispatch } = this.props;
    return dispatch(push('/gardens'));
  };

  handlePlantClick = () => {
    const { dispatch, match } = this.props;
    const { params } = match;
    return dispatch(push(`/gardens/${params.GardenId}/plants/`));
  };

  getEditDialog = (garden, plant) => {
    const { addModalOpen } = this.state;
    return (
      <Dialog open={addModalOpen} onClose={this.handleAddModalClose}>
        <EditEntry
          garden={garden}
          plant={plant}
          entry={{
            quantity: 1,
            size: '',
            date: moment.utc().format('YYYY-MM-DD'),
          }}
          handleClose={this.handleAddModalClose}
        />
      </Dialog>
    );
  };

  render() {
    const { userData, isLoggedIn, classes, match } = this.props;
    const { params } = match;

    if (!isLoggedIn || !userData || !userData.gardens) {
      return (
        <Grid container spacing={24} justify="center" align="center">
          <Grid item>
            <Loader />
          </Grid>
        </Grid>
      );
    }

    const garden = userData.gardens.find(
      (aGarden) => aGarden.id === parseInt(params.GardenId, 0),
    ) || { entries: [] };

    /* eslint-disable-next-line */
    const plant =
      garden.plants.find(
        (aPlant) => aPlant.id === parseInt(params.PlantId, 0),
      ) || {};

    const { entries } = plant;

    if (entries.length === 0) {
      return (
        <div className={classes.root}>
          {this.getEditDialog(garden, plant)}
          <Grid container spacing={24} justify="center" align="center">
            <Grid item xs={12}>
              <Typography className={classes.paddedTop20} variant="display1">
                No entries added
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={this.handleAddClick}
                className={classNames(classes.addButton, classes.noFloat)}
              >
                Add Entry
              </Button>
            </Grid>
          </Grid>
        </div>
      );
    }

    return (
      <div className={classes.root}>
        {this.getEditDialog(garden, plant)}
        <Grid
          align="center"
          justify="space-evenly"
          container
          spacing={24}
          className={classes.grid}
        >
          <Grid item xs={4}>
            <Button
              className={classNames(classes.backButton)}
              variant="contained"
              onClick={this.handleGardenClick}
            >
              <Typography variant="subtitle2">{garden.name}</Typography>
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              className={classNames(classes.backButton, classes.noFloat)}
              variant="contained"
              onClick={this.handlePlantClick}
            >
              <Typography variant="subtitle2">{plant.name}</Typography>
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              className={classNames(classes.addButton)}
              variant="contained"
              onClick={this.handleAddClick}
            >
              <Typography className={classes.white} variant="subtitle2">
                Add Entry
              </Typography>
            </Button>
          </Grid>
          {entries.map((entry) => (
            <Grid item key={entry.id}>
              <Entry
                garden={garden}
                plant={plant}
                entry={Object.assign({}, entry)}
              />
            </Grid>
          ))}
        </Grid>
      </div>
    );
  }
}

Entries.defaultProps = {
  userData: null,
  isLoggedIn: null,
};

Entries.propTypes = {
  userData: PropTypes.object,
  isLoggedIn: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { user: theUser, app } = state;
  const { isLoggedIn, userData } = theUser;
  const { isFetching } = app;

  return {
    isLoggedIn,
    userData,
    isFetching,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(Entries));
