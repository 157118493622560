import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types/prop-types';
import Drawer from '@material-ui/core/es/Drawer/Drawer';
import List from '@material-ui/core/es/List/List';
import ListItem from '@material-ui/core/es/ListItem/ListItem';
import ListItemIcon from '@material-ui/core/es/ListItemIcon/ListItemIcon';
import ListItemText from '@material-ui/core/es/ListItemText/ListItemText';
import { push } from 'connected-react-router';
import globalStyles from '../../styles';
import sprout from './assets/sprout.png';
import adminIcon from './assets/admin.png';

const styles = (theme) => ({
  ...globalStyles(theme),
  list: {
    width: 250,
  },
  sprout: {
    height: 20,
    padding: 2,
  },
  home: {
    color: 'black',
  },
});

class AppMenu extends React.Component {
  state = {};

  handleMenuItemClick = (toLocation) => {
    const { dispatch } = this.props;
    return dispatch(push(`/${toLocation}`));
  };

  render() {
    const { open, classes, handleToggleDrawer, user } = this.props;

    return (
      <div className={classes.root}>
        <Drawer open={open} onClose={() => handleToggleDrawer(false)}>
          <div
            tabIndex={0}
            role="button"
            onClick={() => handleToggleDrawer(false)}
            onKeyDown={() => handleToggleDrawer(false)}
          >
            <div className={classes.list}>
              <List>
                <ListItem
                  button
                  onClick={() => this.handleMenuItemClick('gardens')}
                  key="Gardens"
                >
                  <ListItemIcon>
                    <img alt="" className={classes.sprout} src={sprout} />
                  </ListItemIcon>
                  <ListItemText primary="Gardens" />
                </ListItem>
                {user.role === 'admin' ? (
                  <ListItem
                    button
                    onClick={() => this.handleMenuItemClick('admin')}
                    key="Admin"
                  >
                    <ListItemIcon>
                      <img alt="" className={classes.sprout} src={adminIcon} />
                    </ListItemIcon>
                    <ListItemText primary="Admin" />
                  </ListItem>
                ) : null}
              </List>
            </div>
          </div>
        </Drawer>
      </div>
    );
  }
}

AppMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  handleToggleDrawer: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { user: theUser, app } = state;
  const { isLoggedIn, user } = theUser;
  const { isFetching } = app;

  return {
    isLoggedIn,
    user,
    isFetching,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(AppMenu));
