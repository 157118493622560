export const ADD_IMAGE = 'ADD_IMAGE';
export const DELETE_IMAGE = 'DELETE_IMAGE';

export const addImage = (image) => ({
  type: ADD_IMAGE,
  image,
});

export const deleteImage = (image) => ({
  type: DELETE_IMAGE,
  image,
});
