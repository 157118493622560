const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    margin: '20px',
  },
  paper2: {
    textAlign: 'center',
    padding: 8,
    marginTop: 2,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  appTitle: {
    color: 'white',
  },
  dense: {
    marginTop: 19,
  },
  noFloat: {
    float: 'none !important',
  },
  backButton: {
    padding: '10px',
    float: 'right',
  },
  addButton: {
    backgroundColor: 'green',
    color: 'white',
    padding: '10px',
    float: 'left',
  },
  white: {
    color: 'white',
  },
  fullWidth: {
    width: '100%',
  },
  menu: {
    width: 200,
  },
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    display: 'none',
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    marginBottom: '-20px',
  },
  error: {
    color: 'red',
    fontWeight: 'bold',
    margin: '12px',
  },
  success: {
    color: 'green',
    fontWeight: 'bold',
  },
  grid: {
    height: '100%',
  },
  padded: {
    [theme.breakpoints.up('xs')]: {
      padding: '40px',
    },
  },
  marginTop80: {
    marginTop: 80,
  },
  bordered: {
    border: '1px black',
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  paddedBottom16: {
    paddingBottom: 16,
  },
  card: {
    margin: 10,
    textAlign: 'center',
  },
  verticalCenter: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  grow: {
    flexGrow: 1,
  },
  paddedTop16: {
    paddingTop: 16,
  },
  paddedTop20: {
    paddingTop: 20,
  },
  paddedTop24: {
    paddingTop: 24,
  },
  paddedTop36: {
    paddingTop: 36,
  },
});

export default styles;
