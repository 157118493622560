import axios from 'axios';
import Cookies from 'universal-cookie';
import config from './config';

const cookies = new Cookies();

const { apiURL } = config;

export default {
  addEntry(entry) {
    return axios.post(`${apiURL}/entries`, entry, {
      headers: {
        'x-access-token': cookies.get('accessToken'),
      },
    });
  },

  updateEntry(entry) {
    return axios.put(`${apiURL}/entries/${entry.id}`, entry, {
      headers: {
        'x-access-token': cookies.get('accessToken'),
      },
    });
  },

  deleteEntry(entry) {
    return axios.delete(`${apiURL}/entries/${entry.id}`, {
      headers: {
        'x-access-token': cookies.get('accessToken'),
      },
    });
  },
};
