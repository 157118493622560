import { push } from 'connected-react-router';
import Cookies from 'universal-cookie';
import { setLoading } from '../Shared';
// eslint-disable-next-line
import UserAPI from '../../resources/user';

const cookies = new Cookies();

export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';
export const LOGIN_ATTEMPTED = 'LOGIN_ATTEMPTED';
export const RECEIVE_USER_DATA = 'RECEIVE_USER_DATA';

export const loginAttempted = () => ({
  type: LOGIN_ATTEMPTED,
});

export const userLoggedIn = (user) => ({
  type: USER_LOGGED_IN,
  user,
});

export const userLoggedOut = () => ({
  type: USER_LOGGED_OUT,
});

export const receiveUserData = (userData) => ({
  type: RECEIVE_USER_DATA,
  userData,
});

export const logOutUser = () => (dispatch) => {
  cookies.remove('accessToken', {
    path: '/',
    domain:
      process.env.NODE_ENV === 'production' ? 'gardentracker.app' : null,
  });
  sessionStorage.removeItem('userData');
  dispatch(userLoggedOut());
  dispatch(push('/'));
};

export const getUserData = (id) => async (dispatch) => {
  dispatch(setLoading(true));
  const sessionUserData = sessionStorage.getItem('userData');
  if (!sessionUserData) {
    const response = await UserAPI.getDataForUser(id);
    const userData = response.data;
    sessionStorage.setItem('userData', JSON.stringify(userData));
    dispatch(receiveUserData(userData));
    return dispatch(setLoading(false));
  }
  dispatch(receiveUserData(JSON.parse(sessionUserData)));
  return dispatch(setLoading(false));
};
