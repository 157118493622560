import axios from 'axios';
import Cookies from 'universal-cookie';
import config from './config';

const cookies = new Cookies();

const { apiURL } = config;

export default {
  addImage(image) {
    const formData = new FormData();
    formData.append('image', image);

    /** @param {string} image.EntryId */
    if (image.GardenId) {
      formData.append('GardenId', image.GardenId);
    } else if (image.PlantId) {
      formData.append('PlantId', image.PlantId);
    } else if (image.EntryId) {
      formData.append('EntryId', image.EntryId);
    } else {
      throw new Error('Missing required attribute for image upload');
    }

    return axios.post(`${apiURL}/images`, formData, {
      headers: {
        'x-access-token': cookies.get('accessToken'),
      },
    });
  },

  deleteImage(image) {
    return axios.delete(`${apiURL}/images/${image.id}`, {
      headers: {
        'x-access-token': cookies.get('accessToken'),
      },
    });
  },
};
