import {
  RECEIVE_USER_DATA,
  USER_LOGGED_IN,
  USER_LOGGED_OUT,
  LOGIN_ATTEMPTED,
} from '../../actions/Users';
import {
  LOADING,
  SET_APP_MENU_OPEN_STATUS,
  SET_ADD_DIALOG_OPEN_STATUS,
} from '../../actions/Shared';

const appReducer = (
  state = {
    isFetching: false,
    loginAttempted: false,
    appMenuOpen: false,
    addDialogOpen: false,
  },
  action,
) => {
  switch (action.type) {
    case SET_APP_MENU_OPEN_STATUS:
      return {
        ...state,
        appMenuOpen: action.open,
      };
    case SET_ADD_DIALOG_OPEN_STATUS:
      return {
        ...state,
        addDialogOpen: action.open,
      };
    case LOADING:
      return {
        ...state,
        isFetching: action.isLoading,
      };
    case USER_LOGGED_IN:
    case LOGIN_ATTEMPTED:
      return { ...state, isFetching: false, loginAttempted: true };
    case USER_LOGGED_OUT:
      return { ...state, isFetching: false };
    case RECEIVE_USER_DATA:
      return { ...state, isFetching: false };
    default:
      return state;
  }
};

export default appReducer;
