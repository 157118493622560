export default {
  /**
   * Converts a string to title case
   *
   * @param str - an input string
   *
   * @returns string - output string
   */
  toSentenceCase(str) {
    if (!str) {
      return '';
    }
    return str
      .replace('_', ' ')
      .toLowerCase()
      .split(' ')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
  },

  /**
   * Clones an object (normally React state)
   *
   * @param obj - typically react state, object to clone
   *
   * @returns {*} - cloned object
   */
  // clone(obj) {
  //   return JSON.parse(JSON.stringify(obj));
  // },
};
