export const LOADING = 'LOADING';
export const SET_APP_MENU_OPEN_STATUS = 'SET_APP_MENU_OPEN_STATUS';
export const SET_ADD_DIALOG_OPEN_STATUS = 'SET_ADD_DIALOG_OPEN_STATUS';

export const setLoading = (isLoading) => ({
  type: LOADING,
  isLoading,
});

export const setAppMenuOpenStatus = (open) => ({
  type: SET_APP_MENU_OPEN_STATUS,
  open,
});
