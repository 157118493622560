import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types/prop-types';
import styles from '../../styles';

class Verified extends React.Component {
  state = {};

  render() {
    const { classes } = this.props;

    return (
      <Grid className={classes.grid} container spacing={24}>
        <Grid item xs={12}>
          <h1>Your account is verified!</h1>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" component={Link} to="/">
            Log in
          </Button>
        </Grid>
      </Grid>
    );
  }
}

Verified.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Verified);
