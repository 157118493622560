import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types/prop-types';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Fade from '@material-ui/core/Fade';
import classnames from 'classnames';
import styles from '../../styles/index';
import Loader from '../Shared/Loader';
import GardenAPI from '../../resources/garden';
import { addGarden, updateGarden } from '../../actions/Gardens/index';
import utils from '../../resources/utils';

class EditGarden extends React.Component {
  // eslint-disable-next-line react/destructuring-assignment
  state = { garden: this.props.garden };

  handleSubmit = async (e) => {
    e.preventDefault();

    this.setState({
      submitClicked: true,
      error: null,
      errorField: null,
      success: null,
    });
    const { dispatch, userData, handleClose } = this.props;

    if (this.validateForm()) {
      const { garden } = this.state;
      if (!garden.UserId) {
        garden.UserId = userData.id;
      }

      let response;
      try {
        response = !garden.id
          ? await GardenAPI.addGarden(garden)
          : await GardenAPI.updateGarden(garden);
      } catch (err) {
        utils.handleLoginResponse(err, dispatch);

        return this.setState({ error: err.response.data.message });
      }

      if (!garden.id) {
        dispatch(addGarden(response.data));
      } else {
        dispatch(updateGarden(response.data));
      }

      return handleClose();
    }

    return this.setState({ error: 'Please check your entry and try again.' });
  };

  handleChange = (event) => {
    this.setState({ error: null, errorField: null, success: null });
    const { garden } = this.state;
    const { name, value } = event.target;
    garden[name] = value;
    this.setState({ garden });
  };

  validateForm = () => {
    const { garden } = this.state;
    return garden.name && garden.description;
  };

  render() {
    const { isFetching, classes, userData } = this.props;
    const {
      submitClicked,
      errorField,
      error,
      success,
      garden,
      showSuccess,
    } = this.state;

    if (isFetching || !userData) {
      return (
        <Grid
          className={classes.paddedTop24}
          container
          align="center"
          justify="center"
          spacing={24}
        >
          <Loader />
        </Grid>
      );
    }

    if (!garden) {
      return (
        <Grid
          className={classes.paddedTop24}
          container
          spacing={24}
          justify="center"
          align="center"
        >
          <div>No garden found</div>
        </Grid>
      );
    }

    return (
      <div className={classnames(classes.root, classes.paddedBottom16)}>
        <form className={classes.container} noValidate autoComplete="off">
          <Grid
            container
            spacing={24}
            align="center"
            justify="center"
            className={classes.grid}
          >
            <Paper className={classes.paper}>
              <Grid item xs={12}>
                <h3>{garden.id > 0 ? 'Edit' : 'Add'} Garden</h3>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="name"
                  className={classes.textField}
                  value={garden.name}
                  name="name"
                  onChange={this.handleChange}
                  margin="normal"
                  error={
                    submitClicked && (!garden.name || errorField === 'name')
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="description"
                  className={classes.textField}
                  value={garden.description}
                  name="description"
                  onChange={this.handleChange}
                  margin="normal"
                  error={
                    submitClicked &&
                    (!garden.description || errorField === 'description')
                  }
                />
              </Grid>
              {error ? (
                <Grid item xs={12}>
                  <div className={classes.error}>{error}</div>
                </Grid>
              ) : null}
              <Fade in={showSuccess}>
                <Grid item xs={12}>
                  <div className={classes.success}>{success}</div>
                </Grid>
              </Fade>
              <Grid item xs={12}>
                <Button
                  onClick={this.handleSubmit}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  {garden.id > 0 ? 'Save' : 'Add'} Garden
                </Button>
              </Grid>
            </Paper>
          </Grid>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { user: theUser, app } = state;
  const { isLoggedIn, userData } = theUser;
  const { isFetching } = app;

  return {
    isLoggedIn,
    userData,
    isFetching,
  };
};

EditGarden.defaultProps = {
  userData: null,
};

EditGarden.propTypes = {
  classes: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  userData: PropTypes.object,
  garden: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(withStyles(styles)(EditGarden));
