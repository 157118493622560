import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types/prop-types';
// noinspection ES6CheckImport
import queryString from 'query-string';
import styles from '../../../styles';
import Loader from '../../Shared/Loader';
import Reset from './Reset';
import SendEmail from './SendEmail';

class Verified extends React.Component {
  state = { mode: null };

  componentDidMount() {
    const { location } = this.props;
    const { search } = location;

    const { userId, token } = queryString.parse(search);

    if (!userId || !token) {
      return this.setState({ mode: 'sendemail' });
    }

    return this.setState({ mode: 'reset' });
  }

  render() {
    const { mode } = this.state;

    if (!mode) {
      return <Loader />;
    }

    if (mode === 'reset') {
      return <Reset {...this.props} />;
    }

    return <SendEmail {...this.props} />;
  }
}

Verified.propTypes = {
  location: PropTypes.object.isRequired,
};

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps)(withStyles(styles)(Verified));
