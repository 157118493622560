import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter as Router } from 'connected-react-router';
import ReactRouterPropTypes from 'react-router-prop-types';
import NotFound from './components/NotFound/NotFound';
import Login from './components/Login/Login';
import Signup from './components/Login/Signup';
import Navbar from './components/Navbar/Navbar';
import Profile from './components/Profile/Profile';
import Verified from './components/Login/Verified';
import Gardens from './components/Garden/Gardens';
import Plants from './components/Plant/Plants';
import Entries from './components/Entry/Entries';
import ResetPassword from './components/Login/PasswordReset/ResetPassword';
import Admin from './components/Admin/Admin';

const Routes = (props) => {
  const { history } = props;
  return (
    <Router history={history}>
      <div>
        <Navbar />
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/signup" component={Signup} />
          <Route exact path="/verified" component={Verified} />
          <Route exact path="/resetPassword" component={ResetPassword} />
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/admin" component={Admin} />
          <Route exact path="/gardens" component={Gardens} />
          <Route exact path="/gardens/:GardenId/plants" component={Plants} />
          <Route
            exact
            path="/gardens/:GardenId/plants/:PlantId/entries"
            component={Entries}
          />
          <Route component={NotFound} />
        </Switch>
      </div>
    </Router>
  );
};

Routes.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
};

export default Routes;
