import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types/prop-types';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Fade from '@material-ui/core/Fade';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Loader from '../Shared/Loader';
import EntryAPI from '../../resources/entry';
import { addEntry, updateEntry } from '../../actions/Entries/index';
import utils from '../../resources/utils';
import globalStyles from '../../styles';

const styles = (theme) => ({
  ...globalStyles(theme),
  list: {
    width: 250,
  },
  sprout: {
    height: 20,
    padding: 2,
  },
  home: {
    color: 'black',
  },
  paddedTop: {
    paddingTop: 16,
  },
  selectLabel: {
    float: 'left',
    marginLeft: 8,
  },
});

class EditEntry extends React.Component {
  // eslint-disable-next-line react/destructuring-assignment
  state = { entry: this.props.entry };

  handleSubmit = async (e) => {
    e.preventDefault();

    this.setState({
      submitClicked: true,
      error: null,
      errorField: null,
      success: null,
    });
    const { dispatch, garden, plant, handleClose } = this.props;

    if (this.validateForm()) {
      const { entry } = this.state;
      if (!entry.PlantId) {
        entry.PlantId = plant.id;
      }

      let response;
      try {
        response = !entry.id
          ? await EntryAPI.addEntry(entry)
          : await EntryAPI.updateEntry(entry);
      } catch (err) {
        utils.handleLoginResponse(err, dispatch);

        return this.setState({ error: err.response.data.message });
      }

      if (!entry.id) {
        dispatch(addEntry(response.data, garden.id));
      } else {
        dispatch(updateEntry(response.data, garden.id));
      }

      return handleClose();
    }

    return this.setState({ error: 'Please check your entry and try again.' });
  };

  handleChange = (event) => {
    this.setState({ error: null, errorField: null, success: null });
    const { entry } = this.state;
    entry[event.target.name] = event.target.value;
    this.setState({ entry });
  };

  validateForm = () => {
    const { entry } = this.state;
    return entry.size && entry.quantity && entry.date;
  };

  render() {
    const { isFetching, classes, userData } = this.props;

    const {
      submitClicked,
      errorField,
      error,
      success,
      entry,
      showSuccess,
    } = this.state;

    if (isFetching || !userData) {
      return (
        <Grid
          className={classes.paddedTop}
          container
          align="center"
          justify="center"
          spacing={24}
        >
          <Loader />
        </Grid>
      );
    }

    if (!entry) {
      return (
        <Grid
          className={classes.paddedTop}
          container
          spacing={24}
          justify="center"
          align="center"
        >
          <div>No entry found</div>
        </Grid>
      );
    }

    return (
      <div className={classes.root}>
        <form className={classes.container} noValidate autoComplete="off">
          <Grid
            container
            spacing={24}
            align="center"
            justify="center"
            className={classes.grid}
          >
            <Paper className={classes.paper}>
              <Grid item xs={12}>
                <h3>
                  {entry.id > 0 ? 'Edit' : 'Add'} Entry {entry.id}
                </h3>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="quantity"
                  name="quantity"
                  type="number"
                  className={classes.textField}
                  value={entry.quantity}
                  onChange={this.handleChange}
                  margin="normal"
                  error={
                    submitClicked &&
                    (!entry.quantity || errorField === 'quantity')
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel
                  className={classes.selectLabel}
                  shrink
                  htmlFor="size-label-placeholder"
                >
                  size
                </InputLabel>
                <Select
                  value={entry.size}
                  onChange={this.handleChange}
                  name="size"
                  className={classes.fullWidth}
                >
                  <MenuItem value="small">small</MenuItem>
                  <MenuItem value="medium">medium</MenuItem>
                  <MenuItem value="large">large</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="date"
                  name="date"
                  type="date"
                  className={classes.textField}
                  value={entry.date}
                  onChange={this.handleChange}
                  margin="normal"
                  error={
                    submitClicked && (!entry.date || errorField === 'date')
                  }
                />
              </Grid>
              {error ? (
                <Grid item xs={12}>
                  <div className={classes.error}>{error}</div>
                </Grid>
              ) : null}
              <Fade in={showSuccess}>
                <Grid item xs={12}>
                  <div className={classes.success}>{success}</div>
                </Grid>
              </Fade>
              <Grid item xs={12}>
                <Button
                  onClick={this.handleSubmit}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  {entry.id > 0 ? 'Save' : 'Add'} Entry
                </Button>
              </Grid>
            </Paper>
          </Grid>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { user: theUser, app, router } = state;
  const { isLoggedIn, userData } = theUser;
  const { isFetching } = app;
  const { location } = router;
  const { pathname } = location;

  return {
    isLoggedIn,
    userData,
    isFetching,
    router,
    pathname,
  };
};

EditEntry.defaultProps = {
  userData: null,
};

EditEntry.propTypes = {
  classes: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  userData: PropTypes.object,
  entry: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  plant: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  garden: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(withStyles(styles)(EditEntry));
