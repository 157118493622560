import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import Menu from '@material-ui/core/es/Menu/Menu';
import MenuItem from '@material-ui/core/es/MenuItem/MenuItem';
import Divider from '@material-ui/core/es/Divider/Divider';
import PropTypes from 'prop-types/prop-types';
import styles from '../../styles';

class ProfileMenu extends React.Component {
  state = {};

  render() {
    const { anchorEl, handleClose, open } = this.props;

    return (
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleClose('/profile')}>Profile</MenuItem>
        <Divider />
        <MenuItem onClick={() => handleClose('signout')}>Sign out</MenuItem>
      </Menu>
    );
  }
}

const mapStateToProps = (state) => {
  const { user: theUser, app, router } = state;
  const { isLoggedIn, user } = theUser;
  const { isFetching } = app;
  const { location } = router || { pathname: '' };
  const { pathname } = location;

  return {
    isLoggedIn,
    user,
    isFetching,
    pathname,
  };
};

ProfileMenu.defaultProps = {
  anchorEl: null,
};

ProfileMenu.propTypes = {
  anchorEl: PropTypes.object,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(withStyles(styles)(ProfileMenu));
