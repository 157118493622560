import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/es/Grid/Grid';
import Typography from '@material-ui/core/es/Typography/Typography';
import PropTypes from 'prop-types/prop-types';
import { push } from 'connected-react-router';
import styles from '../../styles';
import Loader from '../Shared/Loader';
import Users from './Users';

class Admin extends React.Component {
  state = {};

  render() {
    const { user, classes } = this.props;

    if (!user) {
      return <Loader />;
    }

    if (user.role !== 'admin') {
      // Not sure how you got here...but bye!
      const { dispatch } = this.props;
      return dispatch(push(`/`));
    }

    return (
      <Grid
        className={classes.paddedTop36}
        container
        spacing={24}
        align="center"
        justify="center"
      >
        <Grid item xs={12}>
          <Typography variant="h4">Admin</Typography>
        </Grid>
        <Grid item xs={12}>
          <Users />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  const { user: theUser, app, router } = state;
  const { isLoggedIn, user } = theUser;
  const { isFetching } = app;
  const { location } = router || { pathname: '' };
  const { pathname } = location;

  return {
    isLoggedIn,
    user,
    isFetching,
    pathname,
  };
};

Admin.defaultProps = {
  user: null,
};

Admin.propTypes = {
  user: PropTypes.object,
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(withStyles(styles)(Admin));
