import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types/prop-types';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import { push } from 'connected-react-router';
import Badge from '@material-ui/core/Badge';
import moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import classnames from 'classnames';
import { deletePlant } from '../../actions/Plants';
import globalStyles from '../../styles';
import PlantAPI from '../../resources/plant';
import apiUtils from '../../resources/utils';
import utils from '../../utils';
import AlertDialog from '../Shared/Confirmation';
import imageHelper from './ImageHelper';
import EditPlant from './EditPlant';
import imageAPI from '../../resources/image';
import { addImage } from '../../actions/Images';
import EditableImage from '../Shared/EditableImage';
import ImageDialog from '../Shared/ImageDialog';

const styles = (theme) => ({
  ...globalStyles(theme),
  card: {
    margin: 10,
  },
  media: {
    maxWidth: '80%',
    height: 180,
    margin: 'auto',
  },
  badge: {
    margin: theme.spacing.unit,
    right: !-10,
  },
});

class Plant extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      deleteModalOpen: false,
      editModalOpen: props.router.location.search === `?edit=${props.plant.id}`,
      imagesModalOpen:
        props.router.location.search === `?images=${props.plant.id}`,
    };
  }

  handleEntriesClick = () => {
    const { dispatch, garden, plant } = this.props;
    return dispatch(push(`/gardens/${garden.id}/plants/${plant.id}/entries`));
  };

  handleDeleteModalClose = async (deleteModel) => {
    if (deleteModel) {
      const { plant, dispatch } = this.props;
      try {
        await PlantAPI.deletePlant(plant);
      } catch (err) {
        apiUtils.handleLoginResponse(err, dispatch);
      }
      dispatch(deletePlant(plant));
    }

    this.setState({ deleteModalOpen: false });
  };

  handleEditModalClose = () => {
    const { dispatch, garden } = this.props;
    this.setState({ editModalOpen: false });
    return dispatch(push(`/gardens/${garden.id}/plants`));
  };

  handleDeleteClick = () => {
    this.setState({ deleteModalOpen: true });
  };

  handleEditClick = () => {
    const { dispatch, garden, plant } = this.props;
    this.setState({ editModalOpen: true });
    return dispatch(push(`/gardens/${garden.id}/plants?edit=${plant.id}`));
  };

  handleImagesClick = () => {
    const { dispatch, plant } = this.props;
    this.setState({
      imagesModalOpen: true,
    });
    return dispatch(push(`plants?images=${plant.id}`));
  };

  handleImagesModalClose = () => {
    const { dispatch } = this.props;
    this.setState({ imagesModalOpen: false });
    return dispatch(push('plants'));
  };

  handleDrop = async (accepted) => {
    const { plant, dispatch } = this.props;

    if (accepted.length > 0) {
      const image = accepted[0];
      image.PlantId = plant.id;
      let response;
      try {
        response = await imageAPI.addImage(image);
      } catch (ex) {
        return null;
      }

      return dispatch(addImage(response.data));
    }
    return null;
  };

  render() {
    const { garden, plant, classes } = this.props;
    const { imagesModalOpen, editModalOpen, deleteModalOpen } = this.state;

    plant.date = moment.utc(plant.date).format('YYYY-MM-DD');

    return (
      <div>
        <AlertDialog
          agree="Delete"
          body={`Are you sure you want to delete plant: ${plant.name}?`}
          closeAction={this.handleDeleteModalClose}
          disagree="Cancel"
          open={deleteModalOpen}
          title="Delete Plant?"
        />
        <Dialog open={editModalOpen} onClose={this.handleEditModalClose}>
          <EditPlant
            garden={garden}
            plant={plant}
            handleClose={this.handleEditModalClose}
          />
        </Dialog>
        <ImageDialog
          open={imagesModalOpen}
          onClose={this.handleImagesModalClose}
          images={plant.images}
        />
        <Card className={classnames(classes.card, classes.textAlignCenter)}>
          <CardActionArea>
            <EditableImage handleDrop={this.handleDrop}>
              <CardMedia
                className={classnames(classes.media, classes.textAlignCenter)}
                image={imageHelper.getImageForPlant(plant)}
                title={utils.toSentenceCase(plant.name)}
              />
              <CardContent>
                <Typography variant="h4">{plant.name}</Typography>
                <Typography variant="h6">{plant.location}</Typography>
                <Typography variant="body2">
                  {moment.utc(plant.date).format('MMMM Do, YYYY')}
                </Typography>
              </CardContent>
            </EditableImage>
          </CardActionArea>
          <CardActions>
            <Badge
              badgeContent={plant && plant.entries ? plant.entries.length : 0}
              className={classes.badge}
              showZero
              color="primary"
            >
              <Button
                onClick={this.handleEntriesClick}
                size="small"
                color="primary"
              >
                Entries
              </Button>
            </Badge>
            <Button onClick={this.handleEditClick} size="small" color="primary">
              Edit
            </Button>
            <Badge
              badgeContent={plant && plant.images ? plant.images.length : 0}
              className={classes.badge}
              showZero
              color="primary"
            >
              <Button
                onClick={this.handleImagesClick}
                size="small"
                color="primary"
              >
                Images
              </Button>
            </Badge>
            <Button
              onClick={this.handleDeleteClick}
              size="small"
              color="primary"
            >
              Delete
            </Button>
          </CardActions>
        </Card>
      </div>
    );
  }
}

Plant.propTypes = {
  plant: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
  garden: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { user: theUser, app, router } = state;
  const { isLoggedIn, userData } = theUser;
  const { isFetching } = app;

  return {
    isLoggedIn,
    userData,
    isFetching,
    router,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(Plant));
