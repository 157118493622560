import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/es/Grid/Grid';
import Typography from '@material-ui/core/es/Typography/Typography';
import PropTypes from 'prop-types/prop-types';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import moment from 'moment';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import UserAPI from '../../resources/user';
import Loader from '../Shared/Loader';
import styles from '../../styles';

class Users extends React.Component {
  static handleUserVerified(user) {
    const { verified } = user;

    if (verified) {
      return (
        <span role="img" aria-label="white-checkmark">
          ✅
        </span>
      );
    }

    return (
      <span role="img" aria-label="red-circle">
        🚫
      </span>
    );
  }

  static handleUserLoginDate(user) {
    const { lastLoginDate } = user;

    if (lastLoginDate) {
      const momentDate = moment(lastLoginDate);
      const date = momentDate.format('MMMM Do, YYYY');
      const time = momentDate.format('h:mm:ss A');
      return (
        <div>
          <span>{date}</span>
          <br />
          <span>{time}</span>
        </div>
      );
    }

    return (
      <div>
        <span>N/A</span>
      </div>
    );
  }

  static clone(obj) {
    return Object.assign({}, obj);
  }

  state = { users: null, open: true };

  async componentDidMount() {
    const users = await this.getUsers();
    this.setState({ users });
  }

  async getUsers() {
    const { dispatch } = this.props;
    const response = await UserAPI.getUsers(dispatch);
    this.setState({ error: null });

    if (response.successful) {
      return response.data;
    }

    this.setState({ error: response.error });
    return [];
  }

  /**
   * Deletes a user
   *
   * @param user
   *
   * @returns null
   */
  async deleteUser(user) {
    const { dispatch } = this.props;
    this.setState({ error: null, success: null });
    if (!user) {
      return null;
    }

    const response = await UserAPI.deleteUser(user, dispatch);
    if (response.successful) {
      const { users } = this.state;
      const newUsers = users.filter((aUser) => {
        return aUser.id !== user.id;
      });
      this.setState({ success: response.message, users: newUsers });
      return null;
    }

    this.setState({ error: response.error });
    return null;
  }

  async handleUserRoleChange(e, user) {
    const { dispatch } = this.props;
    const role = e.target.value;

    const updatedUser = user;

    updatedUser.role = role;
    const response = await UserAPI.updateUserWithoutToken(
      updatedUser,
      dispatch,
    );

    if (response.successful) {
      const { users } = this.state;

      const newUsers = users;
      const index = newUsers.findIndex((aUser) => aUser.id === updatedUser.id);
      newUsers[index] = updatedUser;
      this.setState({ success: response.message, users: newUsers });
      return null;
    }

    this.setState({ error: response.error });
    return null;
  }

  handleUserRole(user) {
    return (
      <Select
        value={user.role}
        style={{ fontSize: 'inherit' }}
        onChange={(e) => this.handleUserRoleChange(e, user)}
      >
        <MenuItem value="admin">Admin</MenuItem>
        <MenuItem value="user">User</MenuItem>
      </Select>
    );
  }

  handleCollapse() {
    const { open } = this.state;
    this.setState({ open: !open });
  }

  handleMessaging() {
    const { classes } = this.props;
    const { success, error } = this.state;

    let message;
    let className;

    if (error) {
      className = classes.error;
      message = error;
    }

    if (success) {
      className = classes.success;
      message = success;
    }

    if (className && message) {
      return (
        <Grid item xs={12}>
          <span className={className}>{message}</span>
        </Grid>
      );
    }

    return null;
  }

  render() {
    const { classes } = this.props;
    const { users, open } = this.state;

    if (!users) {
      return <Loader />;
    }

    return (
      <Grid
        className={classes.paddedTop36}
        container
        spacing={24}
        align="center"
        justify="center"
      >
        <Grid item xs={12} onClick={() => this.handleCollapse()}>
          <Typography variant="h6">
            Users {open ? <ExpandLess /> : <ExpandMore />}
          </Typography>
        </Grid>
        <Collapse in={open} style={{ minWidth: 650, overflowX: 'auto' }}>
          <Grid item xs={12}>
            <Table className={classes.table} aria-label="table">
              <TableHead>
                <TableRow>
                  <TableCell>User ID</TableCell>
                  <TableCell align="right">ID</TableCell>
                  <TableCell align="right">Email</TableCell>
                  <TableCell align="right">Last Login</TableCell>
                  <TableCell align="right">Verified</TableCell>
                  <TableCell align="right">Role</TableCell>
                  <TableCell align="right">Options</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((user) => (
                  <TableRow key={user.id}>
                    <TableCell component="th" scope="row">
                      {user.id}
                    </TableCell>
                    <TableCell align="right">{user.username}</TableCell>
                    <TableCell align="right">{user.email}</TableCell>
                    <TableCell align="right">
                      {Users.handleUserLoginDate(user)}
                    </TableCell>
                    <TableCell align="right">
                      {Users.handleUserVerified(user)}
                    </TableCell>
                    <TableCell align="right">
                      {this.handleUserRole(user)}
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        onClick={() => {
                          this.deleteUser(user);
                        }}
                        variant="contained"
                        color="secondary"
                        type="submit"
                      >
                        Delete User
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Collapse>
        <Grid item xs={12}>
          {this.handleMessaging()}
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

Users.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(withStyles(styles)(Users));
