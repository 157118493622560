import React from 'react';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import PropTypes from 'prop-types/prop-types';
import { Carousel } from 'react-responsive-carousel';
import { withStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import globalStyles from '../../styles';
import { deleteImage } from '../../actions/Images';
import AlertDialog from './Confirmation';
import imageAPI from '../../resources/image';
import apiUtils from '../../resources/utils';

const styles = (theme) => ({
  ...globalStyles(theme),
});

class ImageDialog extends React.Component {
  state = { confirmOpen: false };

  deleteImage = async (confirmDelete) => {
    this.setState({
      confirmOpen: false,
    });

    if (confirmDelete) {
      const { deletedImage: image } = this.state;
      const { dispatch } = this.props;

      try {
        await imageAPI.deleteImage(image);
      } catch (err) {
        apiUtils.handleLoginResponse(err, dispatch);
      }

      return dispatch(deleteImage(image));
    }
    return null;
  };

  render() {
    const { open, images, onClose, classes } = this.props;
    const { confirmOpen } = this.state;

    return (
      <div>
        <AlertDialog
          agree="Delete"
          body="Are you sure you want to delete this image?"
          closeAction={this.deleteImage}
          disagree="Cancel"
          open={confirmOpen}
          title="Delete Image?"
        />
        <Dialog open={open} onClose={onClose}>
          <DialogContent>
            {images.length > 0 ? (
              <div>
                <Carousel
                  dynamicHeight
                  showIndicators={false}
                  showThumbs={false}
                  useKeyboardArrows
                >
                  {images.map((anImage) => (
                    <div key={anImage.id}>
                      <img alt="Not found" src={anImage.path} />
                      <p className="legend" style={{ background: 'none' }}>
                        <Button
                          style={{ backgroundColor: 'red', color: 'white' }}
                          onClick={() =>
                            this.setState({
                              confirmOpen: true,
                              deletedImage: anImage,
                            })
                          }
                        >
                          Delete
                        </Button>
                      </p>
                    </div>
                  ))}
                </Carousel>
              </div>
            ) : (
              <Paper className={classes.paper}>No images uploaded</Paper>
            )}
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { user: theUser } = state;
  const { isLoggedIn, userData } = theUser;

  return {
    isLoggedIn,
    userData,
  };
};

ImageDialog.defaultProps = {
  open: false,
  images: [],
};

ImageDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  images: PropTypes.array,
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(withStyles(styles)(ImageDialog));
