import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types/prop-types';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/es/Typography/Typography';
import { push } from 'connected-react-router';
import AppMenu from './AppMenu';
import globalStyles from '../../styles';
import { logOutUser } from '../../actions/Users';
import ProfileMenu from './ProfileMenu';
import { setAppMenuOpenStatus } from '../../actions/Shared';

const styles = (theme) => ({
  ...globalStyles(theme),
  root: {
    flexGrow: 1,
    paddingBottom: 20,
  },
  appLabel: {
    marginRight: '20px',
    color: 'white',
    fontSize: '19px',
    [theme.breakpoints.only('xs')]: {
      fontSize: '10px',
    },
  },
  menuButton: {},
});

class Navbar extends React.Component {
  state = {
    drawerOpen: false,
    profileOpen: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.appMenuOpen !== prevState.drawerOpen) {
      return { drawerOpen: true };
    }

    return null;
  }

  handleProfileMenu = (e) => {
    this.setState({ anchorEl: e.currentTarget, profileOpen: true });
  };

  handleClose = (type) => {
    this.setState({ anchorEl: null, profileOpen: false });
    const { dispatch } = this.props;

    if (type && type.length > 0) {
      if (type === 'signout') {
        dispatch(logOutUser());
      } else {
        dispatch(push(type));
      }
    }
  };

  handleToggleDrawer = (drawerOpen) => {
    const { dispatch } = this.props;
    this.setState({ drawerOpen });
    return dispatch(setAppMenuOpenStatus(drawerOpen));
  };

  render() {
    const { isLoggedIn, classes, user } = this.props;
    const { anchorEl, drawerOpen, profileOpen } = this.state;

    if (!isLoggedIn) return null;

    return (
      <div className={classes.root}>
        <AppMenu
          open={drawerOpen}
          handleToggleDrawer={this.handleToggleDrawer}
        />
        <AppBar position="static">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="Menu"
              onClick={() => this.handleToggleDrawer(true)}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="title" className={classes.appTitle}>
              GardenTracker
            </Typography>
            <div className={classes.grow} />
            <div>
              <IconButton
                owns={profileOpen ? 'menu-appbar' : undefined}
                aria-haspopup="true"
                onClick={this.handleProfileMenu}
                color="inherit"
              >
                <Typography
                  variant="h6"
                  color="inherit"
                  className={classes.grow}
                >
                  {user.username}
                </Typography>
              </IconButton>
              <ProfileMenu
                open={profileOpen}
                anchorEl={anchorEl}
                handleClose={this.handleClose}
              />
            </div>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

Navbar.defaultProps = {
  user: null,
  isLoggedIn: null,
};

Navbar.propTypes = {
  isLoggedIn: PropTypes.bool,
  user: PropTypes.object,
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { user: theUser, app } = state;
  const { isLoggedIn, user } = theUser;
  const { appMenuOpen } = app;

  return {
    isLoggedIn,
    user,
    appMenuOpen,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(Navbar));
