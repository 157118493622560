import {
  RECEIVE_USER_DATA,
  USER_LOGGED_IN,
  USER_LOGGED_OUT,
} from '../../actions/Users';
import {
  ADD_GARDEN,
  UPDATE_GARDEN,
  DELETE_GARDEN,
} from '../../actions/Gardens';
import { ADD_PLANT, UPDATE_PLANT, DELETE_PLANT } from '../../actions/Plants';
import { ADD_ENTRY, DELETE_ENTRY, UPDATE_ENTRY } from '../../actions/Entries';

import utils from './utils';
import { ADD_IMAGE, DELETE_IMAGE } from '../../actions/Images';

const userReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: true,
        user: action.user,
      };
    case USER_LOGGED_OUT:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case RECEIVE_USER_DATA:
      return {
        ...state,
        userData: action.userData,
      };
    case ADD_GARDEN:
      return {
        ...state,
        userData: utils.addGardenToUserData(action.garden, state.userData),
      };
    case UPDATE_GARDEN:
      return {
        ...state,
        userData: utils.updateGardenInUserData(action.garden, state.userData),
      };
    case DELETE_GARDEN:
      return {
        ...state,
        userData: utils.deleteGardenFromUserData(action.garden, state.userData),
      };
    case ADD_PLANT:
      return {
        ...state,
        userData: utils.addPlantToGardenInUserData(
          action.plant,
          state.userData,
        ),
      };
    case UPDATE_PLANT:
      return {
        ...state,
        userData: utils.updatePlantInGardenInUserData(
          action.plant,
          state.userData,
        ),
      };
    case DELETE_PLANT:
      return {
        ...state,
        userData: utils.deletePlantFromGardenInUserData(
          action.plant,
          state.userData,
        ),
      };
    case ADD_ENTRY:
      return {
        ...state,
        userData: utils.addEntryToPlantInGardenInUserData(
          action.entry,
          action.gardenId,
          state.userData,
        ),
      };
    case UPDATE_ENTRY:
      return {
        ...state,
        userData: utils.updateEntryInPlantInInGardenInUserData(
          action.entry,
          action.gardenId,
          state.userData,
        ),
      };
    case DELETE_ENTRY:
      return {
        ...state,
        userData: utils.deleteEntryFromPlantInGardenInUserData(
          action.entry,
          action.gardenId,
          state.userData,
        ),
      };
    case ADD_IMAGE:
      return {
        ...state,
        userData: utils.addImageToObjectInUserData(
          action.image,
          state.userData,
        ),
      };
    case DELETE_IMAGE:
      return {
        ...state,
        userData: utils.removeImageFromObjectInUserData(
          action.image,
          state.userData,
        ),
      };
    default:
      return state;
  }
};

export default userReducer;
